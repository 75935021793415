/**
 * @file standalone/cr22_mpp.scss
 *
 * Creative Refresh 2022 / Product Grids / Specificity Overrides
 *
 * _product_brief.scss//_filters.scss//_product_grid.scss//_product_shared.scss
 */

@import '../theme-bootstrap';

@if $cr22 {
  div.product {
    &-subline,
    &__description-short {
      margin: 0 0 12px;
      opacity: 1;
    }
    &-brief__header,
    &__detail {
      padding: 0;
      text-align: $ldirection;
    }
    &-brief__info {
      padding: 0 20px;
      .product-brief__image {
        background: transparent;
        .product-results__products & {
          aspect-ratio: 4/5;
        }
      }
    }
    &-brief__header {
      padding-top: 15px;
      margin: 0 auto;
      .mpp-container & {
        margin: 16px 0 0;
        padding: 0 20px;
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
    }
    &-subline {
      @include typestyle--b1;
      font-style: normal;
      color: $color-neutral-gray;
    }
    &-rating {
      display: flex;
    }
    &-brief {
      @include breakpoint($medium-up) {
        padding: 20px 0 60px;
      }
      .mpp-container & {
        @include breakpoint($medium-up) {
          border: unset;
        }
      }
      &__extras-container {
        margin: 8px 0 0;
        padding: 0 0 10px;
        .mpp-container & {
          padding: 0 20px 10px;
          @include breakpoint($medium-up) {
            padding-#{$ldirection}: 18px;
            padding-#{$rdirection}: 18px;
          }
        }
        &::before {
          background-color: $color-white;
        }
      }
      &__description {
        .product-name {
          @include typestyle--h8;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 1.3;
          margin: 0 0 4px;
        }
        .product-subline {
          line-height: 1.2;
          margin: 0 0 12px;
          letter-spacing: 0.01em;
          .mpp-container--dark-background & {
            color: $color-white-80;
          }
        }
        .mpp-container & {
          .product-name {
            color: $color-neutral-dark-gray;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.05em;
            line-height: 1.6;
            @include breakpoint($medium-up) {
              font-size: 14px;
            }
            .mpp-container--dark-background & {
              color: $color-white;
            }
          }
          .product-subline {
            font-size: 15px;
          }
        }
      }
      &__price {
        .mpp-container & {
          .product-price {
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0.01em;
            line-height: 1.5;
          }
        }
      }
      &__image {
        @include breakpoint($medium-up) {
          padding: 0;
        }
        .mpp-container & {
          aspect-ratio: 4/5;
          max-width: 100%;
          .product-image--med {
            max-width: none;
          }
        }
        .mpp-container .product-grid--theme-light-gray & {
          @include gradient-background($color-gradient-setting-grey);
        }
        .mpp-container .product-grid--theme-light-sand & {
          @include gradient-background($color-gradient-setting-beige);
        }
        .mpp-container .product-grid--theme-sand & {
          @include gradient-background($color-gradient-setting-brown);
        }
        .mpp-container .product-grid--theme-light-green & {
          @include gradient-background($color-gradient-setting-green);
        }
        .mpp-container .product-grid--theme-light-blue & {
          @include gradient-background($color-gradient-setting-light-blue);
        }
        .mpp-container .product-grid--theme-pink & {
          @include gradient-background($color-gradient-setting-pink);
        }
        .mpp-container--dark-background .mpp-container & {
          background: linear-gradient($color-gradient-setting-black);
        }
        .product-image--hover {
          transition: opacity $transition-duration;
        }
        .product-image-link {
          .product-image--hover {
            background-color: transparent;
            transition: opacity $transition-duration;
          }
          .mpp-container & {
            background: transparent;
          }
        }
        &:hover {
          .product-image--has-hover {
            transition: opacity 0.1s;
            @include breakpoint($medium-up) {
              opacity: 0;
            }
          }
        }
      }
      &__cta {
        margin: 10px;
        padding: 0;
        .button {
          width: 100%;
        }
        .mpp-container & {
          .link {
            font-size: 15px;
            letter-spacing: 0.01em;
            line-height: 1.5;
          }
        }
        .link {
          .mpp-container--dark-background & {
            border-bottom: 1px solid $color-border;
            color: $color-white-80;
          }
        }
      }
      .product-flag {
        #{$ldirection}: 20px;
        #{$rdirection}: auto;
        top: 0;
      }
      .product-flag__badge {
        background: none;
        border: 0;
        color: $color-neutral-dark-gray;
        font-size: 12px;
        letter-spacing: 0.05em;
        line-height: 1.5;
        text-transform: uppercase;
        width: 100%;
        .mpp-container--dark-background & {
          color: $color-white;
        }
      }
      .mpp-container .product-grid--theme-white & {
        background: $color-white;
      }
      .mpp-container .product-grid--theme-black & {
        background: transparent;
      }
      .product-grid__inline-content {
        .product-grid--theme-black & {
          background: transparent;
        }
      }
      &.product-brief--show-extras-panel-on-hover {
        .product-brief__extras {
          &::after {
            @include breakpoint($medium-up) {
              border: unset;
              box-shadow: none;
            }
          }
          &::before {
            background: none;
          }
        }
        @include breakpoint($medium-up) {
          .product-brief__extras {
            min-height: 100%;
            &:before {
              .no-touchevents .mpp-container .product-grid--theme-light-gray &,
              .no-touchevents .mpp-container .product-grid--theme-light-sand &,
              .no-touchevents .mpp-container .product-grid--theme-sand &,
              .no-touchevents .mpp-container .product-grid--theme-light-green &,
              .no-touchevents .mpp-container .product-grid--theme-black &,
              .no-touchevents .mpp-container .product-grid--theme-light-blue & {
                background-color: unset;
              }
            }
          }
        }
      }
      &.product-brief__extras-container {
        padding: 0 0 10px;
        @include breakpoint($medium-up) {
          padding: 0 10px;
          margin: 20px 0 0;
          background: $color-white;
        }
        .product-carousel & {
          @include breakpoint($medium-up) {
            background: transparent;
          }
        }
      }
    }
    &-brief,
    &-grid__inline-content {
      @include breakpoint($medium-up) {
        border: unset;
        .mpp-container .product-grid--theme-light-gray &,
        .mpp-container .product-grid--theme-light-sand &,
        .mpp-container .product-grid--theme-sand &,
        .mpp-container .product-grid--theme-light-green &,
        .mpp-container .product-grid--theme-light-blue &,
        .mpp-container .product-grid--theme-pink &,
        .mpp-container .product-grid--theme-light-black & {
          background-color: unset;
        }
      }
    }
    &-quickshop {
      @include gradient-background($color-gradient-setting-grey);
      box-shadow: 0 0 0 1px $color-border;
      @include breakpoint($medium-up) {
        background: $color-white;
      }
      &::after {
        box-shadow: unset;
      }
      &__container {
        @include gradient-background($color-gradient-setting-grey);
        .product-quickshop-wrapper & {
          .product-grid--theme-light-sand & {
            @include gradient-background($color-gradient-setting-beige);
          }
          .product-grid--theme-light-green & {
            @include gradient-background($color-gradient-setting-green);
          }
          .product-grid--theme-light-blue & {
            @include gradient-background($color-gradient-setting-light-blue);
          }
          .product-grid--theme-pink & {
            @include gradient-background($color-gradient-setting-pink);
          }
          .product-grid--theme-black & {
            @include gradient-background($color-gradient-setting-black);
          }
        }
      }
      &__content {
        @include breakpoint($medium-up) {
          padding: 60px 0 35px;
        }
      }
      &__header {
        text-align: $ldirection;
        padding: 20px 0;
        @include breakpoint($medium-up) {
          text-align: $ldirection;
          padding: 0;
        }
      }
      &__price-wrapper {
        @include breakpoint($medium-up) {
          border-top: 1px solid $color-extra-light-gray;
          border-bottom: 1px solid $color-extra-light-gray;
        }
      }
      &__summary,
      .sku-list {
        border-bottom: 1px solid $color-extra-light-gray;
      }
      .product-replenishment-select {
        @include breakpoint($medium-up) {
          border-bottom: 1px solid $color-extra-light-gray;
        }
      }
      &__cta {
        .button {
          text-transform: initial;
        }
      }
      &__description {
        .product-name {
          @include typestyle--h3;
          letter-spacing: -1px;
        }
      }
      .product-replenishment-select__one-time--btn {
        @include typestyle--b1;
        padding: 19px 13px 21px;
        height: auto;
        width: auto;
        @include breakpoint($medium-up) {
          padding: 20px 22px;
          height: 64.5px;
        }
      }
      .product-flag {
        #{$ldirection}: 20px;
        #{$rdirection}: auto;
        top: 0;
      }
      .product-flag__badge {
        width: 100%;
        background: none;
        text-transform: uppercase;
        color: $color-neutral-dark-gray;
        border: 0;
      }
    }
    &-grid {
      .mpp-container & {
        background-color: transparent;
      }
      &__content {
        .content-block & {
          @include breakpoint($landscape-up) {
            margin: 0 60px;
          }
        }
      }
      &__item {
        @include breakpoint($landscape-up) {
          min-width: 190px;
        }
      }
      &__add_all_to_bag {
        margin: 40px 0 30px;
      }
      &--carousel {
        .slick-list {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .mpp-container {
    .product-grid__item--tout {
      &.product-grid__item--colspan-1 {
        .product-grid__inline-content .mpp-tout {
          aspect-ratio: 4/5;
          background-size: cover;
          background-repeat: no-repeat;
          height: max-content;
          margin: 0 0 60px;
          position: initial;
          width: 100%;
          @include breakpoint($medium-up) {
            margin: 0 0 45px;
          }
          &--container {
            margin: 0;
            @include breakpoint($medium-up) {
              margin: 0 18px;
              position: relative;
            }
          }
        }
      }
      &.product-grid__item--colspan-2 {
        .product-grid__inline-content .mpp-tout {
          @include breakpoint($landscape-up) {
            aspect-ratio: 1.42;
            background-size: cover;
            background-repeat: no-repeat;
            height: auto;
            max-width: 795px;
            max-height: 555px;
            width: 100%;
          }
        }
      }
    }
    &--custom:not(.mpp-container--custom-v2) {
      @include breakpoint($landscape-down) {
        display: flex;
        flex-wrap: wrap;
        .mpp-filter {
          order: 1;
          width: 50%;
        }
        .mpp-filter-set__selections.active {
          order: 3;
          width: 100%;
        }
        .mpp-sort {
          order: 2;
          width: 50%;
        }
        .mpp-container__intro {
          order: 0;
          width: 100%;
        }
        .product-grid-wrapper {
          margin-top: 20px;
          order: 5;
          width: 100%;
        }
        .filter-no-matches {
          order: 4;
          width: 100%;
        }
      }
      &:not(.mpp-container--has-headline):not(.mpp-container--has-filters) {
        .mpp-container__subheadline {
          @include breakpoint($medium-up) {
            margin-top: 80px;
          }
        }
      }
      &.mpp-container--has-headline {
        margin-top: 40px;
        @include breakpoint($medium-up) {
          margin-top: 80px;
        }
      }
      &.mpp-container--has-filters {
        &.mpp-container--has-headline {
          .mpp-filter {
            @include breakpoint($medium-up) {
              margin-top: -80px;
            }
          }
          .mpp-container__intro {
            @include breakpoint($medium-up) {
              margin-top: -80px;
            }
          }
        }
        .mpp-container__intro {
          @include breakpoint($landscape-up) {
            margin-#{$ldirection}: 40px;
            padding-#{$ldirection}: 0;
          }
        }
        .mpp-container__subheadline {
          @include breakpoint($landscape-up) {
            max-width: 591px;
          }
        }
      }
    }
    .mpp-container__intro {
      float: $ldirection;
      margin: 0 20px;
      @include breakpoint($landscape-up) {
        margin-#{$ldirection}: 18px;
        max-width: 66%;
        padding-#{$ldirection}: 40px;
      }
      .mpp-container__headline:not([class*='typestyle-']) {
        @include typestyle--h1;
        margin-top: 10px;
      }
      .mpp-container__subheadline {
        @include typestyle--b1;
        color: $color-darker-gray;
        @include breakpoint($landscape-up) {
          max-width: 720px;
        }
        .mpp-container--dark-background & {
          color: $color-white-80;
        }
      }
    }
    .mpp-container__headline {
      margin-bottom: 10px;
      margin-top: 5px;
      .mpp-container--dark-background & {
        color: $color-white;
      }
    }
    .mpp-container__subheadline {
      margin-bottom: 20px;
      @include breakpoint($landscape-up) {
        margin-bottom: 40px;
      }
    }
  }
  body.html {
    .product-brief__info {
      padding: 0 10px;
    }
    div.mpp-container .product-brief__info,
    div.mpp-container .product-brief__footer {
      padding: 0;
      @include breakpoint($medium-up) {
        padding: 0 18px;
      }
    }
    .product-brief__extras-container {
      background-color: $color-white;
    }
    &.mpp-container--dark-background {
      .product-brief__extras-container {
        background: $color-neutral-black;
        .link {
          border-bottom: 1px solid $color-border;
          color: $color-white;
        }
      }
    }
    .product-carousel {
      .product-brief__image {
        margin: 0 auto;
        background: none;
      }
    }
    .product-brief__price {
      .product-price,
      .product-price-installment,
      .product-price__prefix,
      .product-price__label {
        color: $color-neutral-gray;
        text-transform: none;
      }
    }
    &.product-full--genaissance {
      .product-brief__price {
        .product-price,
        .product-price__prefix,
        .product-price__label {
          color: $white;
          @include breakpoint($large-up) {
            color: $color-neutral-gray;
          }
        }
      }
    }
    &.mpp-container--dark-background {
      .product-brief__price {
        .product-price,
        .product-price__prefix,
        .product-price__label {
          color: $color-white-80;
        }
      }
    }
    .product-brief__extras::before {
      background: none;
    }
    .product-quickshop {
      .selectBox {
        border-color: $color-extra-light-gray;
      }
    }
    .product-add-to-favorites {
      @include typestyle--b1;
      .link {
        color: $color-neutral-gray;
        border-color: $color-neutral-gray;
        text-transform: initial;
        font-size: 14px;
      }
    }
    .content-block {
      .product-brief__cta {
        margin: 7px 10px;
        padding: 0;
        text-transform: initial;
        text-align: $ldirection;
        .button {
          @include typestyle--b1;
          margin-top: 5px;
          background: none;
          border: none;
          text-align: $ldirection;
          color: $color-black;
          padding: 0;
          border-bottom: 1px $color-black solid;
          width: auto;
          min-width: auto;
          text-transform: initial;
          letter-spacing: 0;
        }
      }
    }
    .mpp-container .product-grid-wrapper {
      .product-grid {
        &__content {
          align-items: flex-start;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          @include breakpoint($large-up) {
            margin: 0 0 20px;
            row-gap: 50px;
            .content-block & {
              margin: auto;
            }
          }
        }
      }
    }
    .product-grid--carousel {
      .slick-prev,
      .slick-next {
        @include breakpoint($large-up) {
          margin-top: 5%;
        }
      }
    }
    .product-quickshop__summary-link {
      @include typestyle--b1;
    }
    .product-grid--theme-black {
      .product-brief__cta {
        .button {
          @include typestyle--b1;
          margin-top: 5px;
          background: none;
          border: none;
          text-align: $ldirection;
          color: $color-black;
          padding: 0;
          border-bottom: 1px $color-black solid;
          width: auto;
          min-width: auto;
          text-transform: initial;
          &:hover {
            color: $color-black;
            background: none;
          }
        }
      }
    }
    .content-block {
      .product-grid--small-cols-2.product-grid--theme-white {
        .product-brief__header {
          padding: 0;
        }
        .product-flag {
          #{$ldirection}: 0;
          top: 0;
          &__badge {
            height: auto;
          }
        }
      }
    }
    .mpp-container {
      .product-brief {
        padding: 0 0 60px;
        @include breakpoint($medium-up) {
          padding: 0 0 45px;
        }
        &__cta {
          color: $color-neutral-dark-gray;
          margin: 8px 20px 0;
          text-align: $ldirection;
          @include breakpoint($medium-up) {
            margin: 0;
          }
        }
      }
    }
  }
  div.mpp {
    &-filter-set {
      .mpp-filter-set__title {
        @include typestyle--h8;
        letter-spacing: 1px;
        font-weight: 400;
      }
      .mpp-filter-set__button {
        @include typestyle--b1;
        color: $color-neutral-gray;
        text-transform: unset;
        letter-spacing: 0;
        &.active {
          border-radius: 0;
        }
      }
    }
    &-sort {
      .mpp-sort-menu {
        border-color: $color-lighter-gray;
      }
    }
    &-container {
      .product-grid__item--tout {
        margin-bottom: 0;
        &.product-grid__item--colspan-2 {
          padding-bottom: 45px;
          @include breakpoint($landscape-up) {
            padding-bottom: 0;
          }
          .product-grid__inline-content {
            background-color: transparent;
            .mpp-tout-v2 {
              position: relative;
              @include breakpoint($medium-up) {
                max-height: 900px;
                max-width: 720px;
              }
              img,
              video {
                aspect-ratio: 4 / 5;
                height: auto;
                object-fit: cover;
                width: 100%;
              }
            }
          }
        }
      }
    }
    &-tout--container-v2 {
      @include breakpoint($medium-up) {
        margin: 0 18px;
      }
      .right-aligned {
        @include breakpoint($medium-up) {
          margin-#{$ldirection}: auto;
          max-width: 720px;
        }
        .mpp-tout__bottom-copy {
          @include breakpoint($medium-up) {
            align-self: flex-start;
          }
        }
      }
    }
    &-container--has-filters {
      .product-grid__item--tout.product-grid__item--colspan-2 {
        .product-grid__inline-content {
          .mpp-tout-v2 {
            aspect-ratio: initial;
            @include breakpoint($medium-up) {
              max-height: 739px;
              max-width: 591px;
            }
          }
        }
      }
      .mpp-tout--container-v2 {
        .right-aligned {
          @include breakpoint($medium-up) {
            max-width: 591px;
          }
        }
        .mpp-tout__bottom-copy {
          @include breakpoint($medium-up) {
            padding-#{$ldirection}: 20px;
            max-width: 591px;
          }
          @include breakpoint($landscape-up) {
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
  }
}
